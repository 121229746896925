






































import { defineComponent, onMounted, toRefs } from '@vue/composition-api';
import { useApi } from '@/util/useApi';
import { useMainStore } from '@/store';
import { activityRegister, getStudyEventDetail } from './api';
import Recommend from '@/components/Recommend.vue';
import { Message, MessageBox } from 'element-ui';

export default defineComponent({
  components: { Recommend },
  setup(_, { root }) {
    const main = useMainStore();
    const { result: detail, refresh } = useApi(
      (id: number) => getStudyEventDetail(id),
      null,
      false,
    );

    onMounted(() => {
      if (typeof root.$route.params.id !== 'undefined') {
        refresh(Number(root.$route.params.id));
      }
    });

    const register = () => {
      if (detail.value) {
        if (main.state.user) {
          MessageBox.confirm('确定要参加活动吗？').then(async () => {
            try {
              await activityRegister(detail.value!.id.toString());
              Message.success('报名成功');
            } catch (e) {
              Message.error(e.error);
            }
          });
        } else {
          main.state.showLogin = true;
        }
      }
    };

    return {
      detail,
      refresh,
      register,
      ...toRefs(main.state),
    };
  },
});
