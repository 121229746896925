import { request } from '@/util/request';
import { StudyResource, StudyVideo, StudyVideoDetail, UserEvent } from './model';

export * from './model';

export function getStudyVideos(page: number, size: number) {
  return request<StudyVideo[]>('/strapi/study-videos', {
    params: {
      _start: (page - 1) * size,
      _limit: size,
    },
  });
}

export function getStudyVideosCount() {
  return request<StudyVideo[]>('/strapi/study-videos/count');
}

export function getStudyVideoDetail(id: number) {
  return request<StudyVideoDetail>(`/strapi/study-videos/${id}`);
}

export function getStudyResources(
  page: number,
  size: number,
  type: 'study-resource' | 'meeting' = 'study-resource',
) {
  return request<StudyResource[]>('/strapi/articles', {
    params: {
      _start: (page - 1) * size,
      _limit: size,
      _sort: 'updated_at:DESC',
      type,
    },
  });
}

export function getStudyResourceDetail(id: number) {
  return request<StudyResource>(`/strapi/articles/${id}`);
}

export function getStudyResourceCount(type: 'study-resource' | 'meeting' = 'study-resource') {
  return request<StudyResource[]>('/strapi/articles/count', {
    params: {
      type,
    },
  });
}

export function getStudyEvents(page: number, size: number) {
  return request<UserEvent[]>('/strapi/events', {
    params: {
      _start: (page - 1) * size,
      _limit: size,
      _sort: 'updated_at:DESC',
    },
  });
}

export function getStudyEventDetail(id: number) {
  return request<UserEvent>(`/strapi/events/${id}`);
}

export function getStudyEventCount() {
  return request<StudyResource[]>('/strapi/events/count');
}

export function activityRegister(eventId: string) {
  return request<StudyResource[]>(`/strapi/events/register/${eventId}`, { method: 'POST' });
}
